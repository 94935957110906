'use client';

import { useTranslations } from 'next-intl';
import React from 'react';
import { useRouter } from 'next/navigation';
import styles from './NotFound.module.scss';
import Button from '@/src/components/UIKit/Button/Button';

const NotFound = () => {
  const t = useTranslations('notFoundPage');
  const router = useRouter();

  return (
    <section className={styles.notFoundPage}>
      <img
        src='/media/images/404.svg'
        alt={t('404')}
        className={styles.notFoundPage__image}
      />
      <div className={styles.notFoundPage__content}>
        <h2 className={styles.notFoundPage__title}>{t('Page Not Found')}</h2>
        <p className={styles.notFoundPage__info}>
          {t('We are sorry please go back to the main page')}
        </p>
      </div>
      <Button onClick={() => router.push('/')}>{t('Back to Main page')}</Button>
    </section>
  );
};

export default NotFound;
